<template>
  <div class="information">
    <div class="informationBox">
      <div class="Tit flexTit">
        <div
          ref="titNameBox"
          style="
            width: 100%;
            text-align: center;
            height: 32px;
            line-height: 32px;
            overflow: hidden;
            margin-right: 10px;
          "
        >
          <!-- {{ gatherData.examName }} -->
          <div
            class="titName"
            v-html="gatherData.examName"
            ref="songName1"
            :class="{ scroll: nameScroll }"
          ></div>
        </div>

        <div>
          <!-- :disabled="!startExam" -->
          <el-button type="primary" @click="draggableOpen" size="small"
            >调座位顺序</el-button
          >
        </div>
      </div>
      <div class="li">
        <span v-if="this.gatherData.arrangeModel == 1"
          >考试时间：{{ gatherData.examStartTime | dayList }} -
          {{ gatherData.examEndTime | dayList }}</span
        >
        <span>考试科目：{{ gatherData.subjectType | subjectTypeFilters }}</span>
        <span v-show="!!gatherData.currExamSession"
          >场次：{{ gatherData.currExamSession }}</span
        >
      </div>
      <div class="li">
        <span>{{ setTime }}</span>
        <span v-show="!!gatherData.sysOrgExamPlaceName"
          >考点：{{ gatherData.sysOrgExamPlaceName }}</span
        >
        <span v-show="!!gatherData.sysOrgExamPlaceRoomName"
          >考场：{{ gatherData.sysOrgExamPlaceRoomName }}</span
        >
      </div>
    </div>
    <div class="seatSelectionList">
      <!-- :class="checkedIndex == i ? 'seatLi activeSeat' : 'seatLi'" -->
      <div
        :class="checkedIndex == i ? 'seatLi newActiveSeat' : 'seatLi'"
        v-for="(val, i) in gatherData.seatOperations"
        :key="i"
        @click="checkedIndex = i"
        :style="
          checkedIndex == i
            ? `background:${bgColor[i]};
          border:0;
          padding:2px 2px 2px 40px
        `
            : ``
        "
      >
        <!-- 旧 -->
        <!-- v-show="val.existExaminee" -->
        <!-- <div class="seatNum">
          <span>座位{{ val.seat }}</span>
          <span>座位{{ val.seatCode }}</span>
          <span v-if="val.examineeStatus == 3">{{ val.totalScore }}分</span>
        </div> -->
        <!-- <div class="seatInformation">
          <span>
            <span
              style="border-color: #d5d5d5; color: #d5d5d5"
              v-if="val.examineeStatus == 2"
              >已交卷</span
            >
            <span
              style="border-color: #d5d5d5; color: #d5d5d5"
              v-else-if="val.examineeStatus == 3"
              >已阅卷</span
            >
            <span style="border-color: #1886fe; color: #1886fe" v-else
              >待交卷</span
            >
          </span>
          <span>
            <span
              style="border-color: #d5d5d5; color: #d5d5d5"
              v-if="val.signatureStatus"
              >已签名</span
            >
            <span style="border-color: #1886fe; color: #1886fe" v-else
              >待签名</span
            >
          </span>
        </div> -->
        <!-- 新 -->
        <div class="seatNumLeft">
          <div>
            <span
              class="fff"
              style="font-size: 18px; font-weight: 700; color: #1886fe"
              >{{ val.seatCode }}</span
            >
            <span>座</span>
          </div>
        </div>
        <div class="seatInformationRight">
          <ul style="font-weight: bold">
            <li
              class="pass"
              v-if="val.examineeStatus == 3 || val.examineeStatus == 2"
            >
              <img src="@/assets/siteScoring/document2.png" />已交卷<span
              ></span>
            </li>
            <li v-else>
              <img src="@/assets/siteScoring/document1.png" />待交卷
            </li>

            <li class="pass" v-if="val.examineeStatus == 3">
              <img src="@/assets/siteScoring/checked2.png" />已阅卷<span
                style="font-size: 13px"
                >({{ val.totalScore }})</span
              >
            </li>
            <li v-else>
              <img src="@/assets/siteScoring/checked1.png" />待阅卷
            </li>
            <li class="pass" v-if="val.signatureStatus">
              <img src="@/assets/siteScoring/edit2.png" />已签名
            </li>
          </ul>
        </div>
      </div>
    </div>
    <div class="topic">试题:{{ showSeatOperations.operationTestName }}</div>
    <el-tabs type="border-card" style="margin: 0 10px">
      <el-tab-pane label="评分" class="scoreBox" style="background-color: #fff">
        <div class="scoreTopBox" :style="`background:${bgColor[checkedIndex]}`">
          <div class="scoreTop" style="font-weight: bold">
            <div class="scoreLeft">评价要点</div>
            <div class="scoreNum">分值</div>
            <div class="scoreOperate">操作</div>
          </div>

          <div class="scoreContent">
            <!-- 要修改 去掉i -->
            <div
              class="scoreUl"
              v-for="(val, i) in showSeatOperations.operationQuestions"
              :key="val.operationQuestionId + i"
            >
              <div
                class="scoreLeft"
                @click="
                  () => {
                    val.showList = !val.showList
                  }
                "
              >
                <div class="ellipsis" v-if="val.showList">
                  {{ i + 1 }}.{{ val.operationQuestionName }}
                </div>
                <span v-else>{{ i + 1 }}.{{ val.operationQuestionName }}</span>
                <!-- 题目长度大于18个字才显示下拉按钮 -->
                <template v-if="val.operationQuestionName.length >= 21">
                  <i v-if="val.showList" class="el-icon-arrow-down"></i>
                  <i v-else class="el-icon-arrow-up"></i>
                </template>
              </div>
              <div class="scoreNum">{{ val.score }}</div>
              <div class="scoreOperate">
                <el-button
                  v-throttle
                  size="mini"
                  :disabled="showSeatOperations.examineeStatus == 3"
                  type="danger"
                  @click="fractionBtn(0, val)"
                  style="
                    height: 32px;
                    border: 0;
                    border-radius: 0;
                    border-top-left-radius: 3px;
                    border-bottom-left-radius: 3px;
                    font-size: 20px;
                  "
                  >零</el-button
                >
                <el-input
                  size="small"
                  type="number"
                  v-model="val.addScore"
                  :disabled="showSeatOperations.examineeStatus == 3"
                  @input="fraction(val)"
                  placeholder="输入分数"
                  ref="inputs"
                  style="border-radius: 0"
                ></el-input>
                <el-button
                  v-throttle
                  size="mini"
                  :disabled="showSeatOperations.examineeStatus == 3"
                  type="success"
                  @click="fractionBtn(1, val)"
                  style="
                    height: 32px;
                    border: 0;
                    border-radius: 0;
                    border-top-right-radius: 3px;
                    border-bottom-right-radius: 3px;
                    font-size: 20px;
                  "
                  >满</el-button
                >
              </div>
            </div>
          </div>
        </div>

        <div class="scoreBottom">
          <span style="font-size: 20px"
            >考生得分:
            <span style="color: #3d981c; font-size: 40px; font-weight: bold">
              {{ showSeatOperations.totalScore }}</span
            >
            分</span
          >

          <div class="scoreBottomBtn">
            <el-button
              v-throttle
              size="small"
              type="primary"
              @click="() => (this.signDrawer = true)"
              >{{
                showSeatOperations.signatureStatus ? '查看' : ''
              }}签名</el-button
            >
            <el-button
              v-throttle
              :disabled="showSeatOperations.examineeStatus == 3"
              size="small"
              type="danger"
              class="set-other-btn"
              @click="allFractionBtn(0)"
              >一键零分</el-button
            >
            <el-button
              v-throttle
              :disabled="showSeatOperations.examineeStatus == 3"
              size="small"
              type="success"
              @click="allFractionBtn(1)"
              class="set-other-btn"
              >一键满分</el-button
            >
            <!-- v-show="this.gatherData.drawing" -->
            <!-- <template v-if="startExam"> -->
            <!-- v-show="this.gatherData.drawing" -->
            <!-- size="small" -->
            <!-- :disabled="showSeatOperations.examineeStatus != 2" -->
            <!-- this.anytimeExamSessionStatus == 2 -->
            <el-button
              v-throttle="true"
              v-if="this.gatherData.arrangeModel == 1"
              class="submitBtnCss"
              type="primary"
              @click="submitBtn"
              :disabled="
                showSeatOperations.examineeStatus == 3 || !this.startExam
              "
              style="padding: 9px 12px"
              >{{
                showSeatOperations.examineeStatus == 3 ? '已' : ''
              }}提交评分</el-button
            >
            <el-button
              v-throttle="true"
              v-else
              class="submitBtnCss"
              type="primary"
              @click="submitBtn"
              :disabled="
                showSeatOperations.examineeStatus == 3 ||
                this.anytimeExamSessionStatus == 1
              "
              >{{
                showSeatOperations.examineeStatus == 3 ? '已' : ''
              }}提交评分</el-button
            >
          </div>
          <!-- </template> -->
        </div>
      </el-tab-pane>
      <!-- v-if="gatherData.examModel == 3" -->
      <el-tab-pane label="试卷拍照" class="photographBox">
        <div style="background: #fff">
          <img
            v-if="showSeatOperations.addanswerHtmlImgAddr"
            :src="this.$imageAddress(showSeatOperations.addanswerHtmlImgAddr)"
          />
          <div
            class="photographBtn"
            @click="PhotographRatingDrawer = true"
          ></div>
        </div>
      </el-tab-pane>
    </el-tabs>
    <!-- <div class="bottomBox">
      <el-button v-throttle  size="medium" type="primary" @click="submitBtn">提交</el-button>
    </div>-->
    <!-- 签名 -->
    <el-drawer
      :withHeader="false"
      title="签名"
      :visible.sync="signDrawer"
      direction="btt"
      size="100%"
    >
      <div class="drawerTop">
        <div>签名确认</div>
        <el-button
          v-throttle
          type="primary"
          size="medium"
          @click="
            () => {
              this.signDrawer = false
            }
          "
          >关闭</el-button
        >
      </div>
      <div class="drawerContent">
        <div class="drawerSign">
          <div class="drawerSignTit">老师电子签名</div>
          <div
            class="drawerSignContent"
            :key="this.showSeatOperations.sceneTeacherSignatureAddr"
          >
            <img
              v-if="!!this.showSeatOperations.signatureStatus"
              :src="
                this.$imageAddress(showSeatOperations.sceneTeacherSignatureAddr)
              "
            />
            <img
              v-else-if="!!this.showSeatOperations.sceneTeacherSignatureAddr"
              :src="
                this.$imageAddress(showSeatOperations.sceneTeacherSignatureAddr)
              "
              @click="signClick(1)"
            />
            <span v-else @click="signClick(1)">点击签名</span>
          </div>
        </div>
        <div class="drawerSign">
          <div class="drawerSignTit">考生电子签名</div>
          <div
            class="drawerSignContent"
            :key="this.showSeatOperations.sceneExamineeSignatureAddr"
          >
            <img
              v-if="!!this.showSeatOperations.signatureStatus"
              :src="
                this.$imageAddress(
                  showSeatOperations.sceneExamineeSignatureAddr
                )
              "
            />
            <img
              v-else-if="!!this.showSeatOperations.sceneExamineeSignatureAddr"
              :src="
                this.$imageAddress(
                  showSeatOperations.sceneExamineeSignatureAddr
                )
              "
              @click="signClick(0)"
            />
            <span v-else @click="signClick(0)">点击签名</span>
          </div>
        </div>
      </div>
      <el-button
        v-throttle
        v-if="
          !this.showSeatOperations.signatureStatus &&
          this.showSeatOperations.examineeStatus == 3 &&
          !!this.showSeatOperations.sceneExamineeSignatureAddr &&
          !!this.showSeatOperations.sceneTeacherSignatureAddr
        "
        @click="lockSignFunc"
        type="primary"
        size="medium"
        style="margin: 0 auto; display: block"
        >提交签名</el-button
      >
      <!-- 签名框 :fullscreen="fullscreen"-->
      <el-dialog
        width="90%"
        :visible="dialogSign"
        :show-close="false"
        :modal="false"
      >
        <canvas
          id="canvasSign"
          @mousedown="down($event)"
          @mousemove="move($event)"
          @touchstart="start($event)"
          @touchmove="move2($event)"
          @mouseup="up"
          @mouseleave="up"
          @touchend="up"
          :width="canvasWidth"
          :height="canvasHeight"
        ></canvas>
        <span slot="title">签名</span>
        <span slot="footer">
          <el-button v-throttle size="medium" type="primary" @click="signSave"
            >保存</el-button
          >
          <el-button v-throttle size="medium" type="danger" @click="signClear"
            >清除</el-button
          >
          <el-button
            v-throttle
            size="medium"
            type="info"
            @click="
              (e) => {
                dialogSign = false
                this.signClear()
              }
            "
            >取消</el-button
          >
        </span>
      </el-dialog>
    </el-drawer>
    <!-- 拍照 -->
    <el-drawer
      :withHeader="false"
      :visible.sync="PhotographRatingDrawer"
      direction="btt"
      size="100%"
    >
      <div class="PhotographRatingDrawerBox">
        <video src id="VidePhotograph"></video>
        <canvas id="canvasPhotograph"></canvas>
        <i class="el-icon-d-arrow-left" @click="returnBtn"></i>
        <div v-if="isFlagView" id="PhotographBtn" @click="PhotographBtn"></div>
        <el-button
          v-throttle
          v-else
          size="medium"
          @click="confirmBtn"
          type="primary"
          class="confirm"
          >确认上传</el-button
        >
      </div>
    </el-drawer>

    <!-- 排序弹框 -->
    <el-dialog
      width="550px"
      title="调座位顺序"
      :visible.sync="draggableVisible"
    >
      <draggable v-model="firstArray2" group="people">
        <div
          style="
            display: inline-block;
            width: 110px;
            font-size: 18px;
            border: 1px solid #999;
            border-radius: 3px;
            padding: 10px;
            margin: 10px;
            box-sizing: border-box;
            text-align: center;
          "
          v-for="item in firstArray2"
          :key="item"
        >
          <span style="color: #1886ff; font-size: 28px; font-weight: bold">{{
            original[item]?.seatCode
          }}</span>
          座
        </div>
      </draggable>
      <span slot="footer" class="dialog-footer">
        <el-button size="small" @click="draggableVisible = false"
          >取 消</el-button
        >
        <el-button size="small" type="primary" @click="draggableRecover"
          >恢 复</el-button
        >
        <el-button size="small" type="primary" @click="draggableBtn"
          >确 定</el-button
        >
      </span>
    </el-dialog>
  </div>
</template>

<script>
import dayjs from 'dayjs'
import draggable from 'vuedraggable'
import { getStore, setStore } from '@/utils/token.js'
import { debounce } from '@/utils/util'
import { Loading } from 'element-ui'
import { countdown } from '@/utils/publicFunc.js'
import { systemTime } from '@/api/system/information.js'
import {
  operationTest,
  getSign,
  sendSign,
  lockSign,
  sceneOperationTest,
  autoSignature,
  sceneData,
  nextStart,
  getNextExamTime,
} from '@/api/PadRating/PadRating.js'
import { fileSingle } from '@/api/fileUpload/fileUpload.js'
export default {
  name: 'siteScoring',
  components: {
    draggable,
  },
  data() {
    return {
      // 座位请求返回
      returnSeat: true,
      // 定时器 setTimeout
      ct: null,
      // 定时器 时间处理
      setTime: '',
      setTimeFunc: null,
      systemTime: 0,
      //  30秒提交
      submitTiming: 0,
      // 开始考试
      startExam: false,
      // 拍照抽屉
      PhotographRatingDrawer: false,
      // 签名抽屉
      signDrawer: false,
      // 签名弹框
      dialogSign: false,
      // 全屏
      // fullscreen: false,
      // 签名角色 老师1 学生0
      signRole: 1,
      // 签名所需 start
      // 画布大小
      canvasWidth: 500,
      canvasHeight: 500,
      // 画布
      can: '',
      // 画布2d
      cxt: '',
      // 用来监听是否按下鼠标
      cxtFlag: false,
      // 画布移动轨迹
      cxtX: 0,
      cxtY: 0,
      // 平板所需参数
      mouseT: 0,
      mouseL: 0,
      // 签名所需 end

      // 视频所需参数start
      videoObj: {},
      tracks: '',
      canvasPhotograph: '',
      videoPhotograph: '',
      canvasImgBase64: '',
      isFlagView: true,
      // 视频所需参数end

      // 获取，修改所需的参数
      gatherData: {
        drawing: false,
        examEndTime: 0, //考试结束时间
        examId: 0, //考试id
        examName: '', //考试名称
        examPlaceId: 0, //考试考点id
        examStartTime: 0, //考试开始时间
        examTimeId: 0, //考试时间id
        // examInterval: 考试间隔
        //已选座位题目
        seatOperations: [
          {
            examineeId: 0, //考生id
            existExaminee: true, //	座位存在考生
            //实操评分项
            operationQuestions: [
              {
                //评议信息
                // "markSuggest": {
                //   "confusion": true,  //存疑
                //   "markSuggestId": 0,  //	阅卷评议id
                //   "remark": ""  //备注
                // },
                operationQuestionId: 0, //操作题目问题id
                operationQuestionName: '', //操作题目问题
                score: 2, //最大分值
                sortNum: 0, //题号
                // 遍历后自行添加
                addScore: '', //得分
                showList: true, //显示
              },
            ],
            operationTestId: 0, //题目Id
            operationTestName: '第1位考生题目', //题目名称
            seat: 0, //	座位号
            // 遍历后自行添加
            addanswerHtmlImgAddr: '', //实验报告
            sceneExamineeSignatureAddr: '', //学生签名
            sceneTeacherSignatureAddr: '', //教师签名
            totalScore: 0, //总分
            signatureStatus: false, //是否签名
            examineeStatus: 1, //考生状态 1待交卷 2待阅卷（已交卷）
          },
        ],
        subjectType: 0, //考试科目
      },
      // 提交
      submitSeatOperations: [
        {
          examineeId: 0, //考生id
          //评分项得分
          operationQuestions: [
            {
              operationQuestionId: 0, //操作题目问题id
              // "operationQuestionName": "",  //操作题目问题
              score: 0, //分值
              sortNum: 0, //题号
            },
          ],
          operationTestId: 0, //	题目id
          answerHtmlImgAddr: [], //实验报告
          // "sceneExamineeSignatureAddr": "",  //学生签名
          // "sceneTeacherSignatureAddr": ""  //教师签名
        },
      ],
      // 选中的下标
      checkedIndex: 0,
      // 显示题目
      showSeatOperations: {},
      // 随到随考所需参数
      // 开始时间
      anytimeExamStartTime: null,
      // 结束时间
      anytimeExamEndTime: null,
      // 1等待考试 2考试中 3考试完成 5场次考试资格取消
      anytimeExamSessionStatus: 1,
      // 判断刷新
      flagF5: false,
      // 新增颜色列表
      bgColor: ['#F7A98A', '#FAAD14', '#5CDBD3', '#85A5FF'],
      // 座位顺序调整
      firstArray: [0, 1, 2, 3],
      firstArray2: [0, 1, 2, 3],
      draggableVisible: false,
      original: [],
      // 滚动
      nameScroll: false,
    }
  },
  // updated () {
  //   this.$forceUpdate();
  // },
  created() {
    if (getStore('firstArray')) {
      this.firstArray = JSON.parse(getStore('firstArray'))
    } else {
      setStore('firstArray', JSON.stringify(this.firstArray))
    }
    // 进入触发
    this.getOperationTest(
      Number(this.$route.query.examTimeId),
      Number(this.$route.query.examId),
      Number(this.$route.query.examPlaceId)
    )
  },
  mounted() {
    this.PageSize()
    window.onresize = () => {
      this.PageSize()
    }
  },
  beforeDestroy() {
    // 离开页面清除定时器
    clearInterval(this.setTimeFunc)
    clearTimeout(this.ct)
    this.setTimeFunc = null
    this.ct = null
  },
  destroyed() {
    // 离开页面清除定时器
    clearInterval(this.setTimeFunc)
    clearTimeout(this.ct)
    this.setTimeFunc = null
    this.ct = null
  },
  methods: {
    // 恢复
    draggableRecover() {
      this.firstArray2.sort(function (a, b) {
        return a - b
      })
      console.log('this.firstArray2 ', this.firstArray2)
    },
    draggableOpen() {
      this.firstArray2 = this.firstArray.filter(
        (i) => i < this.gatherData.seatOperations.length
      )
      this.draggableVisible = true
    },
    draggableBtn() {
      this.firstArray = [...this.firstArray2]
      let missingElements = 4 - this.firstArray2.length
      for (let i = this.firstArray2.length; i < 4; i++) {
        this.firstArray.push(i)
      }
      setStore('firstArray', JSON.stringify(this.firstArray))
      // 重新加载考题
      this.getOperationTest(
        Number(this.$route.query.examTimeId),
        Number(this.$route.query.examId),
        Number(this.$route.query.examPlaceId)
      )
      this.draggableVisible = false
    },
    // 排序
    adjustArrayFunc(secondArray) {
      if (!secondArray || secondArray.length <= 1) return secondArray
      // 过滤掉超出 secondArray 范围的索引
      this.firstArray2 = this.firstArray.filter((i) => i < secondArray.length)
      // 更新 firstArray
      // setStore('firstArray', JSON.stringify(this.firstArray2))
      // 索引映射到对应的对象
      const newArray = this.firstArray2.map((i) => secondArray[i])
      return newArray
    },

    // 监听页面变化
    PageSize() {
      // 适配不一样高度的平板
      // console.log(document.documentElement.clientHeight, '可视高度');
      const h = document.documentElement.clientHeight - 250 - 50 + 20
      document.getElementsByClassName('el-tabs__content')[0].style.height =
        h + 'px'
      document.getElementsByClassName('scoreBox')[0].style.height = h + 'px'
    },
    // 进来执行
    firstFunc() {
      clearInterval(this.setTimeFunc)
      this.setTimeFunc = null
      this.getSystemTime()
      if (this.gatherData.arrangeModel == 1) {
        console.log('定时定点')
        // 定时定点
        this.setTimeFunc = setInterval(() => {
          if (
            !!this.gatherData.examStartTime &&
            !!this.gatherData.examEndTime
          ) {
            this.setTime = this.timeRevise(
              this.gatherData.examStartTime,
              this.gatherData.examEndTime
            )
            this.systemTime += 1000
          }
        }, 1000)
      } else {
        console.log('随到随考')
        this.setTimeFunc = setInterval(() => {
          this.anytimeRevise()
          this.systemTime += 1000
        }, 1000)
      }
    },
    /** 分数处理  start *********************************************/
    // 单个分数处理
    fraction(val) {
      val.addScore = this.$verifyInput(val.addScore, 1, val.score)
      // this.$set(val, "addScore", val.addScore);
      this.allFraction()
    },
    // 总分处理
    allFraction() {
      this.showSeatOperations.totalScore =
        this.showSeatOperations.operationQuestions.reduce((total, e) => {
          if (e.addScore >= 0) {
            total = (total * 1000 + Number(e.addScore) * 1000) / 1000
          }
          return total
        }, 0)
    },
    // 满分零分处理
    fractionBtn(flag, val) {
      if (flag) {
        val.addScore = val.score
      } else {
        val.addScore = 0
      }
      this.allFraction()
    },
    // 一键满分零分处理
    allFractionBtn(flag) {
      this.showSeatOperations.operationQuestions.forEach((val) => {
        val.addScore = flag ? val.score : 0
      })

      this.allFraction()
    },
    /** 分数处理  end */

    /** 签名画布处理  start *********************************************/
    // 初始化画布
    initialization() {
      this.can = document.getElementById('canvasSign')
      this.cxt = this.can.getContext('2d')
      this.cxt.lineJoin = 'round' //线与线的连接方式改为圆形
      this.cxt.lineCap = 'round' //起始点的绘制方式
      this.cxt.lineWidth = 2 //笔画大小
      this.cxt.strokeStyle = 'black' //笔画颜色
      // 平板用到
      this.mouseL =
        document.getElementsByClassName('el-dialog')[0].offsetTop + 61
      this.mouseT =
        document.getElementsByClassName('el-dialog')[0].offsetLeft + 10
    },
    // pc签名按下
    down(e) {
      // console.log(e.offsetY);
      this.cxtFlag = true
      this.initialization()
      this.cxtX = e.offsetX
      this.cxtY = e.offsetY
      this.cxt.beginPath()
      this.cxt.moveTo(this.cxtX, this.cxtY)
    },
    // pc签名移动
    move(e) {
      if (this.cxtFlag) {
        this.cxtX = e.offsetX
        this.cxtY = e.offsetY
        this.cxt.lineTo(this.cxtX, this.cxtY)
        this.cxt.stroke()
      }
    },
    // 平板触摸
    start(e) {
      this.$nextTick(() => {
        this.cxtFlag = true
        this.initialization()
        console.log(this.mouseL, this.mouseT, this.cxtX, this.cxtY)
        this.cxtX = e.touches[0].clientX - this.mouseT
        this.cxtY = e.touches[0].clientY - this.mouseL
        this.cxt.beginPath()
        this.cxt.moveTo(this.cxtX, this.cxtY)
      })
    },
    // 平板移动
    move2(e) {
      if (this.cxtFlag) {
        this.cxtX = e.touches[0].clientX - this.mouseT
        this.cxtY = e.touches[0].clientY - this.mouseL
        this.cxt.lineTo(this.cxtX, this.cxtY)
        this.cxt.stroke()
      }
    },
    // 鼠标或手指离开
    up() {
      this.cxtFlag = false
    },
    // 清除签名
    signClear() {
      this.cxt && this.cxt.clearRect(0, 0, this.can.width, this.can.height)
    },
    // 保存签名
    async signSave() {
      // 调用base64 转换成文件对象上传
      let imgUrl = await this.dataURLtoFile(this.can.toDataURL('image/png'))
      if (this.signRole) {
        this.$set(this.showSeatOperations, 'sceneTeacherSignatureAddr', imgUrl)
      } else {
        this.$set(this.showSeatOperations, 'sceneExamineeSignatureAddr', imgUrl)
      }
      await this.sendSignSubmit()
      this.$forceUpdate()
    },
    // 点击平板开始签名
    signClick(role) {
      this.signRole = role
      this.dialogSign = true
    },
    // 签名上传
    async sendSignSubmit() {
      let data = {
        examId: this.gatherData.examId,
        examTimeId: this.gatherData.examTimeId,
        examineeId: this.showSeatOperations.examineeId,
        sceneExamineeSignatureAddr:
          this.showSeatOperations.sceneExamineeSignatureAddr,
        sceneTeacherSignatureAddr:
          this.showSeatOperations.sceneTeacherSignatureAddr,
        source: 1,
      }
      console.log('签名信息', data)
      const res = await sendSign(data)
      console.log('签名上传', res)
      if (res.success) {
        this.$message.success('保存成功')
      } else {
        this.$message.info('保存失败')
      }
    },
    // 获取签名
    async signReq(val1, val2, val3) {
      const res = await getSign(val1, val2, val3)
      if (res.success) {
        return {
          sceneExamineeSignatureAddr: res.data.sceneExamineeSignatureAddr, //学生签名
          sceneTeacherSignatureAddr: res.data.sceneTeacherSignatureAddr, //教师签名
          signatureStatus: res.data.signatureStatus, //是否锁定
          examineeStatus: res.data.examineeStatus, //
        }
      }
    },

    // 锁定签名
    lockSignFunc() {
      this.$confirm('提交签名后不能修改，是否提交?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
      })
        .then(() => {
          lockSign(
            this.gatherData.examId,
            this.gatherData.examTimeId,
            this.showSeatOperations.examineeId
          ).then((res) => {
            if (res.success) {
              // this.showSeatOperations.signatureStatus = true
              this.$set(this.showSeatOperations, 'signatureStatus', true)

              this.$message.success('提交成功!')
              this.signDrawer = false
              this.$forceUpdate()
            } else {
              this.$message.info('取消提交')
            }
            this.$forceUpdate()
          })
        })
        .catch(() => {
          this.$message.info('取消提交')
        })
    },
    /** 签名画布处理  end */

    // 获取考试信息和座位题目
    getOperationTest(examTimeId, examId, examPlaceId) {
      let loadingInstance = Loading.service({
        fullscreen: true,
        text: '座位信息加载中',
        background: 'rgb(255,255,255)',
      })
      this.returnSeat = false
      // if (!!examTimeId && !!examId && !!examPlaceId) return console.log('没传值');
      operationTest(examTimeId, examId, examPlaceId).then(async (res) => {
        this.gatherData = null
        this.original = JSON.parse(JSON.stringify(res.data.seatOperations))
        res.data.seatOperations = this.adjustArrayFunc(res.data.seatOperations)
        this.returnSeat = true
        console.log('获取考试信息和座位题目', res)
        if (res.success) {
          await Promise.all(
            res.data.seatOperations.map(async (val) => {
              if (val.existExaminee) {
                // 便利获取考生签名
                let {
                  signatureStatus,
                  sceneExamineeSignatureAddr,
                  sceneTeacherSignatureAddr,
                  examineeStatus,
                } = await this.signReq(
                  res.data.examId,
                  res.data.examTimeId,
                  val.examineeId
                )
                // 签名锁定
                val.signatureStatus = signatureStatus
                val.sceneExamineeSignatureAddr = sceneExamineeSignatureAddr //学生签名
                val.sceneTeacherSignatureAddr = sceneTeacherSignatureAddr //教师签名
                val.examineeStatus = examineeStatus //考生状态
              } else {
                val.signatureStatus = false
                val.sceneExamineeSignatureAddr = '' //学生签名
                val.sceneTeacherSignatureAddr = '' //教师签名
                val.examineeStatus = 1
              }
            })
          )
          // 需抽离出来重新循环渲染
          res.data.seatOperations.forEach((val) => {
            val.totalScore = Number(0) //总分
            val.addanswerHtmlImgAddr = '' //实验报告
            // 遍历后自行添加
            val.operationQuestions.forEach((ele) => {
              ele.addScore = ele.addScore == null ? ele.score : ele.addScore
              if (ele.addScore >= 0) {
                // 刷新计算总分
                val.totalScore += Number(ele.addScore)
              }
              ele.showList = true //长度显示
            })
          })

          // 判断是否抽签
          if (res.data.drawing) {
            let { seatOperations } = res.data
            seatOperations = seatOperations.filter(
              (operation) => operation.existExaminee
            )

            if (!seatOperations.length) {
              this.$message.info('所选座位无考生')
              clearInterval(this.setTimeFunc)
              this.ct = setTimeout(() => {
                this.$router.back()
              }, 500)
            }
          }
          this.gatherData = res.data
          // 字体超出 滚动
          this.$nextTick(() => {
            let offset =
              this.$refs.songName1.scrollWidth -
              this.$refs.titNameBox.clientWidth
            if (
              this.$refs.songName1.scrollWidth >
              this.$refs.titNameBox.clientWidth
            ) {
              this.nameScroll = true
              // 设置动画的偏移量
              const keyframes = `
                    @keyframes wordsLoop {
                      0% {transform: translateX(0);}
                      20% {transform: translateX(0);}
                      80% {transform: translateX(-${offset}px);}
                      100% {transform: translateX(-${offset}px);}
                    }`
              // 创建 style 元素并添加到头部
              const styleElement = document.createElement('style')
              styleElement.innerHTML = keyframes
              document.head.appendChild(styleElement)
            } else {
              this.nameScroll = false
            }
          })

          console.log('获取考试信息和座位题目', this.gatherData)
          this.showSeatOperations =
            this.gatherData.seatOperations[this.checkedIndex]
          //
          this.firstFunc()
          setTimeout(() => {
            loadingInstance.close()
          }, 2000)
        } else {
          loadingInstance.close()
          this.$message.info(res.msg)
          clearInterval(this.setTimeFunc)
          this.ct = setTimeout(() => {
            this.$router.replace('/chooseExamRoom')
          }, 500)
        }
        // this.$forceUpdate()
      })
    },

    /** 拍照实验报告  start *********************************************/
    // 退出
    returnBtn() {
      if (this.isFlag()) {
        // 停止视频流
        this.tracks &&
          this.tracks.forEach(function (track) {
            track.stop()
          })
        this.videoObj = {}
        this.PhotographRatingDrawer = false
      } else {
        // 清掉base64
        this.canvasPhotograph
          .getContext('2d')
          .clearRect(
            0,
            0,
            this.canvasPhotograph.width,
            this.canvasPhotograph.height
          )
        this.isFlag()
      }
    },
    // 拍照按钮
    PhotographBtn() {
      this.canvasPhotograph.width = this.videoPhotograph.videoWidth
      this.canvasPhotograph.height = this.videoPhotograph.videoHeight
      //绘制canvas图形
      this.canvasPhotograph
        .getContext('2d')
        .drawImage(this.videoPhotograph, 0, 0)
      //把canvas图像转为img图片
      this.canvasImgBase64 = this.canvasPhotograph.toDataURL('image/png') //1表示质量(无损压缩)
      this.isFlag()
    },
    // 上传实验报告图片
    async confirmBtn() {
      this.showSeatOperations.addanswerHtmlImgAddr = await this.dataURLtoFile(
        this.canvasImgBase64
      )
      // 调用两遍 清除画布及关闭摄像头
      this.returnBtn()
      this.returnBtn()
      this.timeSubmitBtn()
      this.PhotographRatingDrawer = false
    },
    // 判断有无拍照
    isFlag() {
      //系统获取一个空canvas对象
      var blank = document.createElement('canvas')
      blank.width = this.canvasPhotograph.width
      blank.height = this.canvasPhotograph.height
      this.isFlagView = blank.toDataURL() == this.canvasPhotograph.toDataURL()
      return this.isFlagView
    },
    /** 拍照实验报告  end */

    // 提交前处理
    submitBeforeFunc(key = 1) {
      let checkInput = true
      if (key === 2 && !!this.$refs.inputs.length) {
        // 手动提交 未填报红
        for (let index = 0; index < this.$refs.inputs.length; index++) {
          let el = this.$refs.inputs[index]
          el.$el.style = ''
          if (el.value === null || el.value === '' || el.value === undefined) {
            el.$el.style = 'border:1px solid red'
            console.log(el)
            el.focus()
            checkInput = false
            break
          }
        }
      }
      if (!checkInput) {
        this.$message.info('请完整填写分数')
        return false
      }
      this.submitSeatOperations = []
      this.submitSeatOperations = this.gatherData.seatOperations.map((val) => {
        let data1 = {
          examineeId: val.examineeId,
          answerHtmlImgAddr: [val.addanswerHtmlImgAddr],
          operationTestId: val.operationTestId,
          submitStatus: key,
          operationQuestions: val.operationQuestions.map((ele) => {
            return {
              operationQuestionId: ele.operationQuestionId,
              score: ele.addScore,
              sortNum: ele.sortNum,
            }
          }),
        }
        return data1
      })

      return true
    },
    delay(ms) {
      return new Promise((resolve) => setTimeout(resolve, ms))
    },
    submitBtn: debounce(function () {
      let next = this.submitBeforeFunc(2)
      if (!next) return
      //
      let html = `<div>考生姓名：<span style="font-size:26px">${
        this.showSeatOperations.examineeName
      }</span></div><div>座<span style="visibility: hidden;">座位</span>位：<span>${
        this.showSeatOperations.seatCode
      }</span></div>
      <div>考试分数：<span style="color:#1887FE">${
        this.showSeatOperations.totalScore
      }分</span></div>
      <div>实验报告：
        ${
          this.showSeatOperations.addanswerHtmlImgAddr
            ? '<span style="color:#1887FE">图片已上传</span>'
            : '<span style="color:#FE4640">图片未上传</span>'
        }
        </div>
      `

      this.$confirm(html, '提交分数确认', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        dangerouslyUseHTMLString: true,
      })
        .then(() => {
          let handData = JSON.parse(
            JSON.stringify(this.submitSeatOperations[this.checkedIndex])
          )
          handData.submitStatus = 2
          // 添加 Loading 阻塞 防止没返回提交状态时 狂点评分操作
          let loadingInstance = Loading.service({
            fullscreen: true,
            text: '提交中',
          })
          sceneOperationTest(
            this.gatherData.examTimeId,
            this.gatherData.examId,
            // [this.showSeatOperations]
            [handData]
          ).then(async (res) => {
            // 异步延迟 2000 毫秒
            await this.delay(1000)
            loadingInstance.close()
            console.log('确认提交当前座位', res)
            if (res.success) {
              this.$message.success('提交成功!')
              this.$forceUpdate()
            } else {
              this.$message.info(res.msg)
            }
          })
        })
        .catch(() => {
          this.$message.info('取消提交')
        })
    }, 300),
    // 定时提交 30s 一次
    timeSubmitBtn() {
      if (this.showSeatOperations.examineeStatus == 3) return
      this.submitBeforeFunc()
      // console.log(this.submitSeatOperations);
      // console.log(this.checkedIndex);
      // console.log(this.submitSeatOperations[this.checkedIndex]);
      sceneOperationTest(
        this.gatherData.examTimeId,
        this.gatherData.examId,
        // [this.showSeatOperations]
        [this.submitSeatOperations[this.checkedIndex]]
      ).then((res) => {
        console.log('定时提交', res)
      })
    },
    // 最后一次定时提交
    endSubmitBtn() {
      let dataList = []
      dataList = this.gatherData.seatOperations
        .filter((val) => val.existExaminee && val.examineeStatus == 2)
        .map((val) => {
          let data1 = {
            examineeId: val.examineeId,
            answerHtmlImgAddr: [val.addanswerHtmlImgAddr],
            operationTestId: val.operationTestId,
            submitStatus: 2,
            operationQuestions: val.operationQuestions.map((ele) => {
              return {
                operationQuestionId: ele.operationQuestionId,
                score: ele.addScore,
                sortNum: ele.sortNum,
              }
            }),
          }
          return data1
        })
      if (!dataList.length) {
        this.getNextExamTimeFunc(() => {
          this.$router.back()
        })
      } else {
        sceneOperationTest(
          this.gatherData.examTimeId,
          this.gatherData.examId,
          dataList
        ).then((res) => {
          console.log('最后一次提交', res)
          this.getNextExamTimeFunc(() => {
            this.$router.back()
          })
        })
      }
    },

    // 把base64 转换成文件对象上传
    dataURLtoFile(base64Str, fileName = Date.now() + '.png') {
      return new Promise((resolve, reject) => {
        let arr = base64Str.split(','),
          mime = arr[0].match(/:(.*?);/)[1], //base64解析出来的图片类型
          bstr = atob(arr[1]), //对base64串进行操作，去掉url头，并转换为byte atob为window内置方法
          len = bstr.length,
          ab = new ArrayBuffer(len), //将ASCII码小于0的转换为大于0
          u8arr = new Uint8Array(ab) //
        while (len--) {
          u8arr[len] = bstr.charCodeAt(len)
        }
        // 创建新的 File 对象实例[utf-8内容，文件名称或者路径，[可选参数，type：文件中的内容mime类型]]
        let baseFile = new File([u8arr], fileName, {
          type: mime,
        })
        console.log(baseFile)
        let fd = new FormData()
        fd.append('code', 'default')
        fd.append('file', baseFile)
        fileSingle(fd).then((res) => {
          console.log('base64转图片上传', res)
          if (res.success) {
            // this.$message.success('上传成功')
            this.dialogSign = false
            // this.PhotographRatingDrawer = false
            // 清空画布
            this.signClear()
            // 返回所需路径
            console.log(2)
            resolve(res.data.url)
          } else {
            this.$message.info('上传失败:' + res.msg)
            resolve()
          }
        })
      })
    },
    // 时间处理 定时定点
    timeRevise(start, end, newDate = 0) {
      // console.log(start, end, newDate);
      // alert(this.systemTime + "," + Object.is(this.systemTime, NaN) + "," + (this.systemTime == 0))
      if (this.systemTime == 0 || Object.is(this.systemTime, NaN)) {
        return
      }

      newDate = this.systemTime
      let showTime
      if (newDate <= start) {
        showTime = countdown(newDate, start)
        // console.log('showTime', showTime);
        return `距离考试开始：${showTime}`
      } else if (end >= newDate) {
        this.startExam = true
        if (!this.gatherData.drawing && this.returnSeat) {
          clearInterval(this.setTimeFunc)
          this.setTimeFunc = null
          if (isNaN(Number(this.$route.query.examTimeId))) return
          this.getOperationTest(
            Number(this.$route.query.examTimeId),
            Number(this.$route.query.examId),
            Number(this.$route.query.examPlaceId)
          )
          // this.getSystemTime()
          this.setTimeFunc = setInterval(() => {
            if (
              !!this.gatherData.examStartTime &&
              !!this.gatherData.examEndTime
            ) {
              this.setTime = this.timeRevise(
                this.gatherData.examStartTime,
                this.gatherData.examEndTime
              )
              this.systemTime += 1000
            }
          }, 1000)
          // console.log('刷新');
          // window.location.reload()
        }
        this.submitTiming += 1
        if (this.submitTiming >= 30) {
          this.systemTime = this.getSystemTime()
          this.submitTiming = 0
          this.timeSubmitBtn()
        }
        showTime = countdown(newDate, end)

        if (this.submitTiming % 2) {
          // this.getSignStudent()
          this.sceneDataFunc()
        }
        // console.log('showTime', showTime);
        return `剩余时间：${showTime}`
      } else {
        this.startExam = true
        this.submitTiming += 1
        let maxTime = 5 * 60 * 1000
        if (this.gatherData.examInterval < 5) {
          maxTime = this.gatherData.examInterval * 60 * 1000
        }
        if (this.systemTime - end >= maxTime) {
          // this.autoSignatureFunc()
          this.endSubmitBtn()

          clearInterval(this.setTimeFunc)
          this.setTimeFunc = null
        }
        if (this.submitTiming % 2) {
          // this.getSignStudent()
          this.sceneDataFunc()
        }

        showTime = countdown(newDate, end + maxTime)
        return `距评卷结束：${showTime}`
      }
    },
    // 获取系统时间
    getSystemTime() {
      // return new Promise((resolve, reject) => {
      systemTime()
        .then((res) => {
          if (res.success) {
            // console.log('返回系统时间戳');
            // resolve(res.data)
            this.systemTime = res.data
          } else {
            // console.log('返回本地时间戳');
            resolve(new Date().getTime())
          }
        })
        .catch((err) => {
          // console.log('返回本地时间戳');
          resolve(new Date().getTime())
        })
      // })
    },
    //时间处理 随到随考
    anytimeRevise(newDate = 0) {
      if (this.systemTime == 0 || Object.is(this.systemTime, NaN)) {
        return
      }
      newDate = this.systemTime
      this.submitTiming += 1
      if (this.submitTiming >= 30) {
        this.systemTime = this.getSystemTime()
        this.submitTiming = 0
        this.anytimeExamSessionStatus == 2 && this.timeSubmitBtn()
      }
      let thisTime
      switch (this.anytimeExamSessionStatus) {
        case 1:
          if (this.submitTiming % 2) {
            this.angTimeSceneDataFunc()
          }
          this.flagF5 = true
          break
        case 2:
          // 考试开始
          thisTime = countdown(this.anytimeExamStartTime, newDate)
          if (this.flagF5 || !this.gatherData.drawing) {
            if (isNaN(Number(this.$route.query.examTimeId))) return
            this.getOperationTest(
              Number(this.$route.query.examTimeId),
              Number(this.$route.query.examId),
              Number(this.$route.query.examPlaceId)
            )
            this.flagF5 = false
          }
          if (this.gatherData.arrangeModel == 3) {
            this.setTime = `考试已开始： ${thisTime}`
            if (this.submitTiming % 2) {
              this.sceneDataFunc()
            }
            break
          }
        case 3:
          // 已交卷
          if (!this.anytimeExamEndTime) {
            thisTime = countdown(this.anytimeExamStartTime, newDate)
            this.setTime = `考试已开始： ${thisTime}`
          } else {
            thisTime = countdown(
              newDate,
              this.anytimeExamEndTime + 5 * 60 * 1000
            )
            this.setTime = `评卷倒计时：${thisTime}`
            if (newDate >= this.anytimeExamEndTime + 5 * 60 * 1000) {
              this.endSubmitBtn()
            }
          }
          if (this.submitTiming % 2) {
            this.sceneDataFunc()
          }

          break
        case 5:
          clearInterval(this.setTimeFunc)
          this.setTimeFunc = null
          this.ct = setTimeout(() => {
            this.$message.info('本场考试已取消，即将退出…')
            this.$router.back()
          }, 2000)
          break
        default:
          break
      }
    },
    // 获取选中考生的签名
    // async getSignStudent () {
    //   if (!this.showSeatOperations.examineeId) return
    //   if (!this.showSeatOperations.signatureStatus) {
    //     let { signatureStatus, sceneExamineeSignatureAddr, sceneTeacherSignatureAddr, examineeStatus } = await this.signReq(this.gatherData.examId, this.gatherData.examTimeId, this.showSeatOperations.examineeId)
    //     this.showSeatOperations.signatureStatus = signatureStatus
    //     this.showSeatOperations.sceneExamineeSignatureAddr = sceneExamineeSignatureAddr//学生签名
    //     this.showSeatOperations.sceneTeacherSignatureAddr = sceneTeacherSignatureAddr//教师签名
    //     this.showSeatOperations.examineeStatus = examineeStatus//教师签名
    //   }
    // },
    // 自动提交签名
    // autoSignatureFunc () {
    //   let data = []
    //   this.gatherData.seatOperations.forEach(val => {
    //     if (!val.signatureStatus) {
    //       data.push(val.examineeId)
    //     }
    //   });
    //   autoSignature(this.gatherData.examId, this.gatherData.examTimeId, data).then(res => {
    //     if (res.success && res.data) {
    //       this.$router.back()
    //     } else {
    //       this.$message({
    //         type: 'info',
    //         message: res.msg
    //       });
    //     }
    //   })
    // },
    // 考试|场次|考生签名 状态
    sceneDataFunc() {
      let data = this.gatherData.seatOperations
        .filter((val) => val.existExaminee)
        .map((val) => val.examineeId)

      if (isNaN(Number(this.$route.query.examTimeId))) return
      sceneData(
        Number(this.$route.query.examId),
        Number(this.$route.query.examTimeId),
        data
      ).then((res) => {
        // console.log('考试|场次|考生签名 状态', res);
        if (res.success) {
          if (res.data.examStatus != 7) {
            if (res.data.examSessionStatus != 4) {
              // res.data.sceneExamSignatureList.forEach(val => {
              //   val.examineeId
              // });
              this.gatherData.seatOperations.forEach((operation) => {
                if (operation.existExaminee) {
                  let signature = res.data.sceneExamSignatureList.find(
                    (sig) => sig.examineeId === operation.examineeId
                  )
                  if (signature) {
                    operation.examineeStatus = signature.examineeStatus
                    operation.signatureStatus = signature.signatureStatus
                    operation.sceneExamineeSignatureAddr =
                      signature.sceneExamineeSignatureAddr
                    operation.sceneTeacherSignatureAddr =
                      signature.sceneTeacherSignatureAddr
                    operation.addanswerHtmlImgAddr = signature.answerHtmlImgAddr
                      ? signature.answerHtmlImgAddr[0]
                      : ''
                  }
                }
              })

              let delS = false
              // 删除缺考，取消考试的考生
              this.gatherData.seatOperations =
                this.gatherData.seatOperations.filter((operation, index) => {
                  if (
                    operation.examineeStatus == 4 ||
                    operation.examineeStatus == 5
                  ) {
                    this.$message.info(`座位${operation.seat}已取消考试`)
                    delS = true
                    return false
                  }
                  return true
                })

              // 判断是否随到随考
              if (this.gatherData.arrangeModel != 1) {
                this.anytimeExamSessionStatus = res.data.examSessionStatus
                this.anytimeExamStartTime = res.data.examStartTime
                this.anytimeExamEndTime = res.data.examEndTime
                // 随到随考考试完成
                if (res.data.examSessionStatus == 3) {
                  this.nextStartFunc()
                }
              }

              if (this.gatherData.seatOperations.length == 0) {
                this.$router.back()
              }
              if (delS) {
                this.checkedIndex = 0
              }
            } else {
              clearInterval(this.setTimeFunc)
              this.setTimeFunc = null
              this.$message.info('本场考试已取消，即将退出…')
              this.ct = setTimeout(() => {
                this.$router.back()
              }, 2000)
            }
          } else {
            clearInterval(this.setTimeFunc)
            this.setTimeFunc = null
            this.$message.info('考试已终止，即将退出…')
            this.ct = setTimeout(() => {
              // 返回两层
              this.$router.go(-2)
            }, 2000)
          }
        }
        this.$forceUpdate()
      })
    },
    // 考试|场次|考生签名 状态  定时定点 还未考试时触发
    angTimeSceneDataFunc() {
      if (isNaN(Number(this.$route.query.examTimeId))) return
      sceneData(
        Number(this.$route.query.examId),
        Number(this.$route.query.examTimeId),
        []
      ).then((res) => {
        // console.log('考试|场次|考生签名 状态  定时定点 还未考试时触发', res)
        if (res.success) {
          this.anytimeExamSessionStatus = res.data.examSessionStatus
          this.anytimeExamStartTime = res.data.examStartTime
          this.anytimeExamEndTime = res.data.examEndTime
        }
        this.$forceUpdate()
      })
    },
    // 随到随考 判断下一场考试是否开始
    nextStartFunc() {
      if (isNaN(Number(this.$route.query.examTimeId))) return
      nextStart(
        Number(this.$route.query.examId),
        Number(this.$route.query.examTimeId)
      ).then((res) => {
        // console.log('判断下一场考试是否开始', res);
        if (res.success) {
          if (res.data) {
            // this.$message.info('下场考试已开始，即将退出')
            this.ct = setTimeout(() => {
              // this.$router.back()
              this.endSubmitBtn()
              //
            }, 2000)
          }
        }
      })
    },
    // 考试结束后获取考试时间
    async getNextExamTimeFunc(callback) {
      const examId = Number(this.$route.query.examId)
      const examPlaceId = Number(this.$route.query.examPlaceId)
      const examTimeId = Number(this.$route.query.examTimeId)
      if (isNaN(examPlaceId) || isNaN(examTimeId)) return
      await getNextExamTime(examId, examPlaceId, examTimeId)
        .then((res) => {
          if (res.success) {
            const newExamTimeId = Number(res.data)
            if (examTimeId == newExamTimeId) return // 轮询避免重复提示及请求考题
            const newQuery = {
              examTimeId: newExamTimeId,
              examId: examId,
              examPlaceId: examPlaceId,
            }
            this.$router.replace({ query: newQuery })
            this.$message.info('下场考试已开始')
            window.location.reload()
            // 获取操作测试数据
            this.getOperationTest(newExamTimeId, examId, examPlaceId)
          } else {
            console.log('考试结束后获取考试时间', res.msg)
            // this.$message.info(res.msg)
            callback && callback()
          }
        })
        .catch((err) => {
          console.error('An error occurred:', err)
        })
    },
  },
  watch: {
    // fullscreen () {
    //   console.log(this.fullscreen);
    //   if (this.fullscreen) {
    //     const h = document.documentElement.clientHeight
    //     console.log('全屏');
    //   } else {
    //     console.log('非全');
    //   }
    // },
    // 监听获取弹框宽度
    dialogSign() {
      this.$nextTick(() => {
        if (this.dialogSign) {
          this.canvasWidth =
            document.getElementsByClassName('el-dialog__body')[0].clientWidth -
            20
        }
      })
    },
    checkedIndex() {
      if (this.gatherData.drawing) {
        // this.getSignStudent()
        this.sceneDataFunc()
      }
      this.showSeatOperations =
        this.gatherData.seatOperations[this.checkedIndex]
      this.$forceUpdate()
      if (!!this.$refs.inputs.length) {
        for (let index = 0; index < this.$refs.inputs.length; index++) {
          let el = this.$refs.inputs[index]
          el.$el.style = ''
        }
      }
    },
    // 监听打开摄像头
    PhotographRatingDrawer() {
      if (this.PhotographRatingDrawer) {
        // 清除
        // Loading
        let loadingInstance = Loading.service({ fullscreen: true })
        document.getElementsByClassName(
          'el-loading-mask'
        )[0].style.zIndex = 9999
        // let loadingInstance = Loading.service({ fullscreen: true });

        this.$nextTick(() => {
          this.canvasPhotograph = document.getElementById('canvasPhotograph')
          // 先清空画布
          this.canvasPhotograph
            .getContext('2d')
            .clearRect(
              0,
              0,
              this.videoPhotograph.videoWidth,
              this.videoPhotograph.videoHeight
            )

          this.videoPhotograph = document.getElementById('VidePhotograph')
          // 判断是PC还是平板 调用后置或前置摄像头
          this.videoObj = {
            // "video": true
            video: {
              facingMode: { exact: 'environment' },
            },
          }
          //请求摄像头权限
          window.navigator.mediaDevices
            .getUserMedia(this.videoObj)
            .then((stream) => {
              this.tracks = stream.getTracks()
              this.videoPhotograph.srcObject = stream
              this.videoPhotograph.play()
              loadingInstance.close()
            })
            .catch((err) => {
              console.log(err)
              loadingInstance.close()
            })
        })
      }
    },
  },
  filters: {
    dayList(val) {
      // return dayjs(val - 28800000).format('HH:mm:ss');
      return dayjs(val).format('HH:mm:ss')
    },
    subjectTypeFilters(key) {
      switch (key) {
        case 1:
          return `物理`
        case 2:
          return `生物`
        case 3:
          return `化学`
        default:
          return `subjectType值为${key}`
      }
    },
  },
}
</script>

<style lang="scss" scoped>
.informationBox {
  margin: 4px 0;
  .Tit {
    line-height: 24px;
    min-height: 24px;
    padding: 4px 0;
    font-size: 24px;
    font-weight: bold;
    // text-align: center;
  }
  .flexTit {
    padding: 0 10px;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  .li {
    text-align: center;
    height: 14px;
    font-size: 14px;
    line-height: 14px;
    padding: 4px 0;
    span {
      display: inline-block;
      margin: 0 18px;
    }
  }
}

.seatSelectionList {
  display: flex;
  justify-content: space-around;
  box-sizing: border-box;
  // padding: 0 10px;
  .seatLi {
    margin: 0 4px;
    width: calc(100% - 8px);
    height: 80px;
    border: 1px solid #bfbfbf;
    cursor: pointer;
    box-sizing: border-box;
    border-radius: 6px;
    display: flex;
    position: relative;
    padding: 0;
    padding-left: 40px;
    background: #f7f9fc;
    // text-align: center;
    // &:first-of-type {
    //   border-top-left-radius: 6px;
    //   border-bottom-left-radius: 6px;
    // }
    // &:last-of-type {
    //   border: 1px solid #bfbfbf;
    //   border-top-right-radius: 6px;
    //   border-bottom-right-radius: 6px;
    // }
    // .seatNum {
    //   height: 28px;
    //   box-sizing: border-box;
    //   padding-top: 6px;
    //   font-size: 18px;
    //   line-height: 22px;
    //   display: flex;
    //   justify-content: space-around;
    // }
    // .seatInformation {
    //   height: 40px;
    //   display: flex;
    //   > span {
    //     height: 52px;
    //     justify-content: center;
    //     width: 50%;
    //     font-size: 15px;
    //     line-height: 50px;
    //     span {
    //       font-style: normal;
    //       border: 1px solid #ccc;
    //       border-radius: 6px;
    //       box-sizing: border-box;
    //       padding: 4px 8px;
    //       height: 20px;
    //     }
    //   }
    // }
    // 新
    // 左
    .seatNumLeft {
      position: absolute;
      width: 40px;
      height: 100%;
      left: 0;
      top: 0;
      overflow: hidden;
      display: flex;
      justify-content: center;
      align-items: center;
      span {
        width: 100%;
        display: block;
        text-align: center;
      }
    }
    // 右
    .seatInformationRight {
      width: 100%;
      height: 100%;
      background: #ffffff;
      border-radius: 6px;
      border-top-left-radius: 0px;
      border-bottom-left-radius: 0px;
      display: flex;
      justify-content: center;
      align-items: center;
      ul {
        list-style: none;
        padding: 0;
        margin: 0;
      }
      li {
        margin: 0;
        padding: 0;
        display: flex;
        align-items: center;
        margin-bottom: 6px;
        position: relative;
        color: #bfbfbf;
        height: 18px;
        font-size: 16px;
        line-height: 18px;
        img {
          margin-right: 4px;
          width: 16px;
          height: 16px;
        }
        &:before {
          content: '';
          display: inline-block;
          position: absolute;
          left: 7px;
          bottom: -7px;
          width: 1px;
          height: 8px;
          background: #bfbfbf;
          z-index: 1;
        }
        &:last-child {
          margin-bottom: 0;
          &:before {
            display: none;
          }
        }
      }
      li.pass {
        color: #52c41a;
        &:before {
          background: #52c41a;
        }
      }
    }
  }
  // 选中时的
  .activeSeat {
  }
}
.topic {
  margin: 6px 0px 8px 6px;
  font-weight: bold;
  height: 16px;
  font-size: 16px;
  line-height: 16px;
  text-align: center;
}
// 88 + 80 + 40 + 32 + 46

// 238 40
// 278
.bottomBox {
  width: 100%;
  height: 46px;
  box-sizing: border-box;
  padding: 0 10px 10px 0;
  position: fixed;
  bottom: 0;
  left: 0;
  display: flex;
  justify-content: flex-end;
}
::v-deep .el-tabs--border-card {
  border: 0;
  // box-shadow: 0 2px 2px 0 rgb(0 0 0 / 12%), 0 0 0px 0 rgb(0 0 0 / 4%);
  box-shadow: 0 0px 0px 0 rgb(0 0 0 / 12%), 0 0 0px 0 rgb(0 0 0 / 4%);
}
::v-deep .el-tabs--border-card > .el-tabs__header {
  background-color: #fff;
}
::v-deep .el-tabs__item {
  background-color: #dfe6ec;
  border-top-right-radius: 8px;
  border-top-left-radius: 8px;
}
::v-deep .el-tabs__content {
  background-color: #dfe6ec;
  border-top-right-radius: 8px;
  border-bottom-right-radius: 8px;
  border-bottom-left-radius: 8px;
}
::v-deep .el-tabs--border-card > .el-tabs__header {
  border-bottom: 2px solid #fff;
}
::v-deep .el-tabs--border-card > .el-tabs__header .el-tabs__item.is-active {
  background-color: #1886fe;
  color: #fff;
}
::v-deep .el-dialog__body {
  padding: 10px;
}
::v-deep .el-dialog__footer {
  display: flex;
  justify-content: center;
}

::v-deep .el-tabs__content {
  width: 100%;
  box-sizing: border-box;
  height: calc(100vh - 282px);
  overflow-y: auto;
  padding: 0;
  // height: 100vh;
}
.scoreBox {
  width: 100%;
  box-sizing: border-box;
  padding: 35px 6px 52px 6px;
  position: relative;
  overflow: hidden;
  .scoreTopBox {
    position: absolute;
    width: 100%;
    // height: calc(100% - 52px);
    padding: 0 10px;
    // padding-bottom: 10px;
    border-bottom-right-radius: 8px;
    border-bottom-left-radius: 8px;
    height: calc(100% - 52px);
    top: 0;
    left: 0;
    overflow: hidden;
    box-sizing: border-box;
  }
  .scoreTop {
    // position: absolute;
    // left: 0;
    // top: 0;
    width: 100%;
    height: 35px;
    line-height: 35px;
    padding: 6px;
    line-height: 23px;
    font-size: 18px;
    box-sizing: border-box;
    display: flex;
    .scoreLeft {
      // width: calc(100% - 224px);
      // width: calc(100% - 202px);
      width: calc(100% - 196px);
      text-align: center;
    }
    .scoreNum {
      text-align: center;
      box-sizing: border-box;
      width: 52px;
    }
    .scoreOperate {
      text-align: center;
      // width: 172px;
      width: 150px;
      width: 138px;
    }
  }
  .scoreBottom {
    position: absolute;
    left: 0;
    bottom: 0;
    width: 100%;
    box-sizing: border-box;
    height: 52px;
    padding: 10px 6px;
    display: flex;
    align-items: flex-end;
    justify-content: space-between;
    span {
      // margin-left: 5px;
      // margin-right: 10px;
      display: inline-block;
      font-size: 18px;
    }
  }
  .scoreContent {
    width: 100%;
    // height: 100%;
    height: calc(100% - 45px);
    overflow-y: auto;
    box-sizing: border-box;
    border-radius: 8px;
    .scoreUl {
      width: 100%;
      background: transparent;
      display: flex;
      margin-bottom: 5px;
      .el-input {
        // margin-left: 5px;
        ::v-deep .el-input__inner {
          padding: 0;
          width: 62px;
          text-align: center;
          font-size: 15px;
        }
      }
      .el-button + .el-button {
        margin-left: 5px;
      }
      .el-button--mini,
      .el-button--mini.is-round {
        padding: 8px;
      }
      .scoreLeft {
        background: #fff;
        // width: calc(100% - 224px);
        width: calc(100% - 202px);
        border-radius: 6px;
        padding: 0 20px 0 6px;
        overflow: hidden;
        box-sizing: border-box;
        display: flex;
        align-items: center;
        flex-wrap: wrap;

        position: relative;
        span {
          display: inline-block;
          width: 100%;
          line-height: 26px;
        }
        .ellipsis-2 {
          width: 100%;
          height: 46px;
          // white-space: nowrap;
        }
        i {
          position: absolute;
          right: 5px;
        }
      }
      .scoreNum {
        background: #fff;
        margin: 0 6px;
        box-sizing: border-box;
        width: 40px;
        border-radius: 6px;
        display: flex;
        justify-content: center;
        align-items: center;
      }
      .scoreOperate {
        padding: 8px;
        display: flex;
        align-items: center;
        background: #fff;
        border-radius: 6px;
      }
    }
  }
}
.photographBox {
  width: 100%;
  height: 100%;
  box-sizing: border-box;
  padding: 6px 6px 58px 6px;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  background: #fff;
  background-clip: content-box;
  img {
    max-width: 100%;
    max-height: 100%;
  }
  .photographBtn {
    cursor: pointer;
    width: 50px;
    height: 50px;
    box-sizing: border-box;
    border: 6px solid #69666a;
    position: absolute;
    margin: 0 auto;
    bottom: 4px;
    left: calc(50% - 25px);
    background: #fff;
    border-radius: 50%;
    &:hover {
      border: 6px solid #69666a8a;
    }
  }
}
// 签名
::v-deep .el-drawer__body {
  position: relative;
}
.drawerTop {
  display: flex;
  justify-content: space-between;
  align-items: center;
  box-sizing: border-box;
  padding: 18px;
  border-bottom: 1px solid #ccc;
  font-weight: bolder;
  font-size: 20px;
}
.drawerContent {
  // height: calc(100% - 78px);
  height: calc(100% - 118px);
  width: 100%;
  .drawerSign {
    height: 50%;
    width: 100%;
    .drawerSignTit {
      padding: 0 10px;
      font-size: 18px;
      line-height: 46px;
      height: 46px;
      background: #dfe5ea;
    }
    .drawerSignContent {
      margin: 10px;
      height: calc(100% - 66px);
      box-shadow: 0 0 6px #69666aa8;
      border-radius: 6px;
      display: flex;
      justify-content: center;
      align-items: center;
      img {
        max-width: 100%;
        max-height: 100%;
      }
      span {
        width: 100%;
        height: 100%;
        display: flex;
        font-size: 38px;
        letter-spacing: 4px;
        color: #b8b8b8;
        justify-content: center;
        align-items: center;
        cursor: pointer;
      }
    }
  }
}
// 66 51 20
#canvasSign {
  box-shadow: 0 0 4px #69666ab7;
  border-radius: 4px;
}
// 摄像头
.PhotographRatingDrawerBox {
  width: 100vw;
  height: 100%;
  position: relative;
  #VidePhotograph {
    position: absolute;
    width: 100%;
    height: 100%;
    z-index: 2;
    top: 0;
    left: 0;
    // background: #1886fe;
  }
  #canvasPhotograph {
    position: absolute;
    width: 100%;
    height: 100%;
    z-index: 4;
    top: 0;
    left: 0;
  }
  .canvasPhotograph {
    position: absolute;
    width: 100%;
    height: 100%;
    z-index: -1;
    top: 0;
    left: 0;
  }
  .el-icon-d-arrow-left {
    font-size: 30px;
    position: absolute;
    z-index: 5;
    left: 10px;
    bottom: 26px;
  }
  #PhotographBtn {
    position: absolute;
    border-radius: 50%;
    background: #fff;
    bottom: 20px;
    left: calc(50% - 30px);
    z-index: 5;
    cursor: pointer;
    width: 50px;
    height: 50px;
    box-sizing: border-box;
    border: 6px solid #69666a;
  }
  .el-button {
    position: absolute;
    bottom: 26px;
    right: 10px;
    z-index: 5;
  }
}
.el-loading-mask {
  background-color: rgba(255, 255, 255, 1) !important;
}
// 提交评分单独脱离
.submitBtnCss {
  position: fixed;
  bottom: 10px;
  left: calc(50% - 56px) !important;
  width: 112px;
}

// 分数输入框修改
::v-deep .scoreOperate .el-input__inner {
  border-radius: 0;
  font-size: 20px;
  font-weight: bold;
  margin-left: 0;
}

.scoreBottomBtn {
  .set-other-btn {
    padding: 9px 12px;
  }
}
.newActiveSeat {
  .fff {
    color: #fff !important;
    font-weight: 900 !important;
    font-size: 20px !important;
    transition: all 0.3s;
  }
}

.titName {
  white-space: nowrap;
  display: inline-block;
}
.titName.scroll {
  animation: 6s wordsLoop linear infinite;
}
// @keyframes wordsLoop {
//   0% {
//     transform: translateX(0px);
//   }
//   // 停顿
//   20% {
//     transform: translateX(0px);
//   }
//   80% {
//     transform: translateX(calc(-2.35rem));
//   }
//   // 停顿
//   100% {
//     transform: translateX(calc(-2.35rem));
//   }
// }
</style>
